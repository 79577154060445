// Setup2FA.tsx
import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import UserAlert from "../Alerts/user";
import classes from "./Verify2FA.module.scss";
import {Form, Typography,Input,Modal} from "antd"
import {CustomButton} from "../Button"
import {useDispatch, useSelector} from "react-redux";
import {userAC} from "../../store/branches/user/actionCreators";
import {selectUserState} from "../../store/selectors";
import {useHistory} from "react-router-dom";
import clsx from "clsx"
import LandingFooter from "../LandingFooter";
import {RuleItem} from "../../pages/Verification/components/RuleItem";
import {LoadingStatus} from "../../store/status";
import LandingHeader from "../LandingHeader";
import VerificationAlert from "../Alerts/verification";
const {Title} = Typography;

const Verify2FA: React.FC = () => {

    const [token, setToken] = useState<string>('');

    const dispatch = useDispatch();
    const history = useHistory();

    const handleVerify2FA = async () => {
        dispatch(userAC.verify2FA({token: token}));
    };


    return (

            <div>
                <div className={classes.headerBlock}>
                    <LandingHeader  onLogin={() => {
                    }} isRegister={false} is2FAVerify={true}/>
                    <VerificationAlert/>

                    <div className={classes.content}>
                        <div className={classes.informationBlockWrap}>
        <div className={classes.passwordFormWrap}>
            <React.Fragment>
                <UserAlert/>

                <div className={classes.mainTitleWrap}>
                    <h2 className={classes.mainTitle}>Verify Security</h2>
                </div>

                <div className={classes.mainTitleWrap}>
                    <h3 className={classes.mainDesc}>Please enter the code on your device</h3>
                </div>
                <React.Fragment>
                    <div className={classes.container}>
                        <div className={classes.inputPasswordWrap}>
                            <div className={classes.inputTitleWrap}>
                            <span
                                className={classes.inputTitle}>Code</span>
                            </div>
                            <div>

                                <Form.Item>
                                    <Input
                                        type="text"
                                        width="150px"
                                        placeholder="Enter token"
                                        value={token}
                                        onChange={(e) => setToken(e.target.value)}
                                        className={classes.passwordInput}
                                    />

                                </Form.Item>

                            </div>


                        </div>
                    </div>
                    <div className={classes.buttonWrap}>
                        <Form.Item>
                            <CustomButton width="150px"
                                          height="40px"
                                          padding="2px 2px"
                                          fontSize="13px"
                                          htmlType="submit"
                                          onClick={handleVerify2FA}>
                                <span>Verify 2FA</span>
                            </CustomButton>
                        </Form.Item>
                    </div>
                </React.Fragment>


                <div>
                    <LandingFooter/>
                </div>
            </React.Fragment>
        </div>
                        </div>
                    </div>
                </div>
            </div>

    );
};

export default Verify2FA;
