import {
    AddSite,
    CurrentLocation,
    EditAssignUser,
    FetchAssignUserData,
    SetMemberShip,
    SitesState,
    UpdateMemberShip,
    UserData,
} from "./stateTypes";
import {
    AddSiteAI,
    ChangeActivateCurLocAI,
    ClearAssignUsersAI,
    ClearCurrentSiteAI,
    ClearCurrentUserAI,
    ClearSelectSitesAI,
    ClearSitesAI,
    DeactivateCurrentLocationAI,
    FetchAssignUsersAI,
    FetchCurrentLocationAI,
    FetchCurrentUserAI,
    FetchSiteAccessAI,
    FetchSitesAI,
    FetchTimezonesAI,
    RemoveAssignUserAI,
    RemoveCurrentLocationAI,
    SelectSitesAI,
    SendActivationLinkAI,
    SetAssignUsersAI,
    SetAssignUsersCountAI,
    SetCurrentLocationAI,
    SetCurrentUserAI,
    SetLocationMemberShipAI,
    SetNewStateSitesAI,
    SetOperationStatusSiteAI,
    SetSiteAccessAI,
    SetSitesAI,
    SetSitesLoadingStatusAI,
    SetTimezonesAI,
    SetUserAI,
    ShadowFetchSitesAI,
    SitesAT,
    UpdateAssignUserAI,
    UpdateCurrentLocationAI,
    UpdateLocationMemberShipAI,
    DISABLEUSER2FAAI,
} from "./actionTypes";
import { disable2FA } from "../user/sagas";


export const sitesAC = {

    selectClient: (payload: string): SelectSitesAI => ({
        type: SitesAT.SELECT_SITES,
        payload,
    }),

    fetchSites: (): FetchSitesAI => ({
        type: SitesAT.FETCH_SITES,
    }),

    shadowFetchSites: (): ShadowFetchSitesAI => ({
        type: SitesAT.SHADOW_FETCH_SITES,
    }),

    fetchSiteAccess: (): FetchSiteAccessAI => ({
        type: SitesAT.FETCH_SITE_ACCESS,
    }),

    fetchAssignUsers: (payload: FetchAssignUserData): FetchAssignUsersAI => ({
        type: SitesAT.FETCH_ASSIGN_USERS,
        payload,
    }),

    fetchCurrentLocation: (payload: string): FetchCurrentLocationAI => ({
        type: SitesAT.FETCH_CURRENT_LOCATION,
        payload,
    }),

    setSites: (payload: SitesState["sitesData"]): SetSitesAI => ({
        type: SitesAT.SET_SITES,
        payload,
    }),

    setNewStateSites: (payload: SitesState["sitesData"]): SetNewStateSitesAI => ({
        type: SitesAT.SET_NEW_STATE_SITES,
        payload,
    }),

    fetchTimezones: (): FetchTimezonesAI => ({
        type: SitesAT.FETCH_TIMEZONES,
    }),

    setTimezones: (payload: SitesState["timezones"]): SetTimezonesAI => ({
        type: SitesAT.SET_TIMEZONES,
        payload,
    }),

    setSiteAccess: (payload: SitesState["site_access"]): SetSiteAccessAI => ({
        type: SitesAT.SET_SITE_ACCESS,
        payload,
    }),

    setCurrentLocation: (payload: SitesState["current_location"]): SetCurrentLocationAI => ({
        type: SitesAT.SET_CURRENT_LOCATION,
        payload,
    }),

    setAssignUsers: (payload: SitesState["assign_users"]): SetAssignUsersAI => ({
        type: SitesAT.SET_ASSIGN_USERS,
        payload,
    }),

    setAssignUsersCount: (payload: number): SetAssignUsersCountAI => ({
        type: SitesAT.SET_ASSIGN_USERS_COUNT,
        payload,
    }),

    setSitesLoadingStatus: (payload: SitesState["status"]): SetSitesLoadingStatusAI => ({
        type: SitesAT.SET_LOADING_STATE,
        payload,
    }),

    setOperationStatusSite: (payload: SitesState["status_operation"]): SetOperationStatusSiteAI => ({
        type: SitesAT.SET_SITES_STATUS_OPERATION,
        payload,
    }),

    setUser: (payload: UserData): SetUserAI => ({
        type: SitesAT.SET_USER,
        payload,
    }),

    updateAssignUser: (payload: { assign_user_id: number, data: UserData }): UpdateAssignUserAI => ({
        type: SitesAT.UPDATE_ASSIGN_USER,
        payload,
    }),

    addSite: (payload: AddSite): AddSiteAI => ({
        type: SitesAT.ADD_SITE,
        payload,
    }),

    setLocationMemberShip: (payload: SetMemberShip): SetLocationMemberShipAI => ({
        type: SitesAT.SET_LOCATION_MEMBERSHIP,
        payload,
    }),

    updateLocationMemberShip: (payload: UpdateMemberShip): UpdateLocationMemberShipAI => ({
        type: SitesAT.UPDATE_LOCATION_MEMBERSHIP,
        payload,
    }),

    updateCurrentLocation: (payload: CurrentLocation): UpdateCurrentLocationAI => ({
        type: SitesAT.UPDATE_CURRENT_LOCATION,
        payload,
    }),

    removeCurrentLocation: (payload: string): RemoveCurrentLocationAI => ({
        type: SitesAT.REMOVE_CURRENT_LOCATION,
        payload,
    }),

    deactivateCurrentLocation: (payload: string): DeactivateCurrentLocationAI => ({
        type: SitesAT.DEACTIVATE_CURRENT_LOCATION,
        payload,
    }),

    changeActivateCurLoc: (payload: boolean): ChangeActivateCurLocAI => ({
        type: SitesAT.CHANGE_ACTIVATE_CURRENT_LOCATION,
        payload,
    }),

    clearSites: (): ClearSitesAI => ({
        type: SitesAT.CLEAR_SITES,
    }),

    clearSelectSites: (): ClearSelectSitesAI => ({
        type: SitesAT.CLEAR_SELECT_SITES,
    }),

    clearCurrentSite: (): ClearCurrentSiteAI => ({
        type: SitesAT.CLEAR_CURRENT_SITE,
    }),

    clearAssignUsers: (): ClearAssignUsersAI => ({
        type: SitesAT.CLEAR_ASSIGN_USERS,
    }),

    fetchCurrentAssignUser: (payload: string): FetchCurrentUserAI => ({
        type: SitesAT.FETCH_CURRENT_ASSIGN_USER,
        payload,
    }),
    clearCurrentUser: (): ClearCurrentUserAI => ({
        type: SitesAT.CLEAR_CURRENT_ASSIGN_USER,
    }),

    setCurrentUser: (payload: EditAssignUser): SetCurrentUserAI => ({
        type: SitesAT.SET_CURRENT_ASSIGN_USER,
        payload,
    }),
    removeAssignUser: (payload: {assign_user_id: number, location_id: string, isUpdateUserPage: boolean}): RemoveAssignUserAI => ({
        type: SitesAT.REMOVE_ASSIGN_USER,
        payload,
    }),
    sendActivationLink: (payload: number): SendActivationLinkAI => ({
        type: SitesAT.SEND_ACTIVATION_LINK,
        payload
    }),
    disableUser2FA: (payload: number): DISABLEUSER2FAAI => ({
        type: SitesAT.DISABLE_USER_2FA,
        payload
    }),
};

export type SitesActions =
    | SelectSitesAI
    | FetchSitesAI
    | FetchSiteAccessAI
    | FetchAssignUsersAI
    | FetchCurrentLocationAI
    | SetSitesAI
    | FetchTimezonesAI
    | SetTimezonesAI
    | SetSiteAccessAI
    | SetAssignUsersAI
    | SetCurrentLocationAI
    | SetLocationMemberShipAI
    | UpdateLocationMemberShipAI
    | UpdateCurrentLocationAI
    | RemoveCurrentLocationAI
    | DeactivateCurrentLocationAI
    | ClearSitesAI
    | ClearSelectSitesAI
    | ClearAssignUsersAI
    | ClearCurrentSiteAI
    | ChangeActivateCurLocAI
    | SetSitesLoadingStatusAI
    | SetAssignUsersCountAI
    | SetOperationStatusSiteAI
    | SetNewStateSitesAI
    | SetCurrentUserAI
    | FetchCurrentUserAI
    | ClearCurrentUserAI
    | RemoveAssignUserAI
    | UpdateAssignUserAI
    | SendActivationLinkAI 
    | DISABLEUSER2FAAI
